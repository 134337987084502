import Auth from '@/modules/Auth/Auth';
import FormAPI from '@/modules/Auth/APIs/FormAPI';
import { RouteLocationNormalized } from 'vue-router';

function handleProductionEnvironment(live: boolean, redirect: string) {
  if (!live) {
    var url = redirect || "https://www.fau.edu/elearning/";
    window.location.replace(url);
  }
}

function handleDevelopmentEnvironment() {
  console.log("This form is displayed in dev view, but not in live view");
}

export default class FormRouter {
  private _auth: Auth;

  constructor(auth: Auth) {
    this._auth = auth;
  }

  async beforeEach(to: RouteLocationNormalized){
    var form = to.params.id as string;
  try {
    const formAPI = new FormAPI(this._auth);
    const meta = await formAPI.GetFormMeta(form);
    const { requiresAuth, live, redirect,displayName } = meta as { requiresAuth: boolean; live: boolean; redirect: string; displayName:string };

    document.title = displayName + " - FAU COCE";

    if (process.env.NODE_ENV === 'production') {
      handleProductionEnvironment(live, redirect);
    } else {
      handleDevelopmentEnvironment();
    }

    if (requiresAuth) {

      const currentUser = await this._auth.GetCurrentUser();
      // if the user is not logged in, redirect to the login page
      if (!currentUser) {
        return {
          path: '/login',
          query: {
            // we keep the current path in the query so we can
            // redirect to it after login with
            // `router.push(route.query.redirect || '/')`
            redirect: "/" + form,
          },
        };
      }

      // if the user is not a FAU user, redirect to the wrong account page
      if(!this._auth.IsFAUUser(currentUser)){
        return {
          path: '/wrong-account',
          query: {
            // we keep the current path in the query so we can
            // redirect to it after login with
            // `router.push(route.query.redirect || '/')`
            redirect: "/" + form,
          },
        };
      }

    }
  } catch (e) {
    console.error(e);
    return {
      path: "/not-found"
    };
  }
  }
}
