<script>
  /*******************************************************************
    This is using a composable from orginal repo and overriding some components to use our components
  ****************************************************************************/

  import logic from '@ditdot-dev/vue-flow-form/src/composables/QuestionTypes/EmailType'
  import TextType from './TextType';

  logic.extends = TextType  // this is pulling the template and script

  export default logic
</script>