<template>
  <!--this is overriding the super component-->
  <div class="w-full">
    <input 
      ref="input"
      type="file"
      class="text-lg text-fau-blue-bright file:transition-all
              file:mr-4 file:py-2 file:px-4 file:border-solid file:border-2 
              file:text-fau-blue-bright file:border-fau-blue-bright file:bg-transparent
              hover:file:bg-fau-blue-bright hover:file:text-white hover:file:cursor-pointer"
      v-bind:accept="question.accept"
      v-bind:multiple="question.multiple"
      v-bind:value="modelValue"
      v-bind:required="question.required"
      v-on:keyup.enter.prevent="onEnter"
      v-on:keyup.tab.prevent="onEnter"
      v-on:focus="setFocus"
      v-on:blur="unsetFocus"
      v-on:change="onChange"
    />
  </div>
</template>

<script>
/*******************************************************************
  This is using a composable from orginal repo and overriding some components to use our components
****************************************************************************/

  import logic from '@ditdot-dev/vue-flow-form/src/composables/QuestionTypes/FileType'
  import TextType from './TextType';

  logic.extends = TextType // this is pulling the script
  export default logic
</script>