import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import FormView from '@/views/FormView.vue'
import LoginVue from '@/views/Login.vue'
import NotFAUView from '@/views/NotFAU.vue'
import HomeView from '@/views/Home.vue'
import NotFoundView from '@/views/NotFound.vue'
import { inject } from 'vue'
import Auth from '@/modules/Auth/Auth'
import FormRouter from './modules/Form/FormRouter'

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    component: HomeView,
    beforeEnter(to, from, next) {
      window.location.href = "https://www.fau.edu/elearning/";
    }
  },
  {
    path: '/:id',
    component: FormView,
    props: true,
    meta: {
      requiresAuth: true,
      AuthType: "Form"
    },
  },
  {
    path: '/login',
    component: LoginVue,
  },
  {
    path: '/wrong-account',
    component: NotFAUView,
  },
  {
    path: '/not-found',
    component: NotFoundView,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
})

router.beforeEach(async (to) => {

  var { requiresAuth,AuthType } = to.meta as { requiresAuth: boolean, AuthType: string};

  if (requiresAuth) {
    if (AuthType == "Form") {

      
      const auth = inject('auth') as Auth;
      const formRouter = new FormRouter(auth);// <-- a fan if this being recreate every time
      
      var newRoute = await formRouter.beforeEach(to);
      if(newRoute) {
        return newRoute;
      }
    }
  }
})

export default router
