export default class ImageAPI {
    SERVERURL : string;

    constructor(){
        this.SERVERURL = process.env.VUE_APP_BACKEND_URL || "http://localhost:3000"

        if(!process.env.VUE_APP_BACKEND_URL){
            console.error("The backurl was not provided... Did we forgot something?")
}
    }

    GetImage_Sync(image: string){
        return `${this. SERVERURL}/images/${image}`
    }
}