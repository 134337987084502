export default class BasicAPI {

    SERVERURL : string;

    constructor(){
        this.SERVERURL = process.env.VUE_APP_BACKEND_URL || "http://localhost:3000"

        if(!process.env.VUE_APP_BACKEND_URL){
            console.error("The backurl was not provided... Did we forgot something?")
}
    }

    async GetData(call: string, query?:object){
        var parms;
    
        if(query)
            parms = "?" + new URLSearchParams(query as any)
        else
            parms = ""
    
        var data = await fetch(`${this.SERVERURL}${call}${parms}`)
    
        return await data.json();
    }
}