import QuestionModel, { QuestionType } from "@ditdot-dev/vue-flow-form/src/models/QuestionModel.js";
import { BaseQuestion } from "../BaseQuestion";
import QuestionConverter from "./QuestionConverter";

export default class NotImplementedQuestionConverter extends QuestionConverter {
    convert(q: BaseQuestion) : any {
      return new QuestionModel({
        tagline: "Not Implemented",
        title: "Not Implemented",
        type: QuestionType.Text,
        placeholder: "Not Implemented",
        description: "Not Implemented"
      });
    }
}