<template>
  <div id="FlowFormQuestion" 
        class="animate-[fadeIn_1s_ease-in-out]
        relative mt-8"
        :class="{
          'hidden': !this.active
        }" ref="qanimate">
    <!--Question Box-->
    <div class="relative z-1">
      <!--Tag Line-->
      <div class="text-fau-blue-bright font-light pr-8" :class="{
        'text-2xl md:text-3xl': question.type != QuestionType.SectionBreak,
        'text-4xl pt-16': question.type === QuestionType.SectionBreak
      }" v-if="question.tagline" v-html="question.tagline"></div>
      <!--Chat Bubble-->
      <div class="bg-fau-white p-6 md:p-10 mt-8 w-fit text-fau-text-black chat-bubble ">
        <div class="text-2xl md:text-3xl font-light text-fau-blue" v-html="question.title"></div>
        <span v-if="question.subtitle" v-html="question.subtitle"></span>

        <!--Michael from the past here
        We are really hard coding text here but we should use the question.helpText to override them in the future. 
        Right now, we dont support the question.helpText but we could.. :O -->
        <span class="f-help break-words" v-if="question.type === QuestionType.Text">Please provide the answer in the text box below</span>
        <span class="f-help break-words" v-if="question.type === QuestionType.File">Please click "Choose File"</span>
        <span class="f-help break-words" v-if="question.type === QuestionType.Email">Please provide the answer in the text box below</span>
        <span class="f-help break-words" v-if="question.type === QuestionType.Dropdown">Please select your answer in the dropdown below</span>
        <span class="f-help break-words" v-if="question.type === QuestionType.MultipleChoice && question.multiple" >Please choose at least one of the buttons below</span>
        <span class="f-help break-words" v-else-if="question.type === QuestionType.MultipleChoice">Please choose one of the buttons below</span>

        <span class="f-help break-words" v-if="question.type === QuestionType.LongText && !isMobile"
          v-html="question.helpText || language.formatString(language.longTextHelpText)"></span>

        <!--We want SectionBreak Text within chat bubble-->
        <template v-if="question.type === QuestionType.SectionBreak">
          <div class="mt-5">
            <component ref="questionComponent" v-bind:is="question.type" v-bind:question="question"
              v-bind:language="language" v-bind:tokens="tokens" v-model="dataValue" v-bind:active="active"
              v-bind:disabled="disabled" v-on:next="onEnter" />
          </div>
        </template>

      </div>
      
      <div class="w-3 h-3 bg-fau-blue-bright rounded-full ml-[-6px] mt-8 animate-pulse"></div>
      <!--Question that are not SectionBreaks stay out of chat bubble-->
      <div v-if="question.type != QuestionType.SectionBreak" class="mt-8 w-full">
        <component ref="questionComponent" v-bind:is="question.type" v-bind:question="question" v-bind:language="language"
          v-bind:tokens="tokens" v-model="dataValue" v-bind:active="active" v-bind:disabled="disabled"
          v-on:next="onEnter" />
      </div>
      <!--descripion is not supported right now-->
      <p v-if="question.description || question.descriptionLink.length !== 0" class="f-description text-sm text-fau-silver mt-4">
        <span v-if="question.description" v-html="question.description"></span>
        <a v-for="(link, index) in question.descriptionLink" class="f-link" v-bind:key="'m' + index"
          v-bind:href="link.url" v-bind:target="link.target">{{ link.text || link.url }}</a>
      </p>
    </div>
    <!--Submit Button-->
    <div class="mt-10 animate-[fadeIn_1s_ease-in-out] flex items-center gap-x-5" v-if="showOkButton()">
      <button class="bg-fau-blue-bright text-fau-white text-xl px-7 py-3" type="button" ref="button" href="#" v-on:click.prevent="onEnter"
        v-bind:aria-label="language.ariaOk">
        <span v-if="question.type === QuestionType.SectionBreak">Start</span>
        <span v-else-if="showSkip()">Skip</span>
        <span v-else>Next</span>
      </button>

      <template v-if="question.type !== QuestionType.LongText || !isMobile">
        <div class="hidden md:block border border-fau-silver w-20 h-0"></div>
        <a class="f-enter-desc hidden md:block text-fau-silver font-light text-sm" href="#" v-if="question.type !== QuestionType.LongText || !isMobile"
          v-on:click.prevent="onEnter">Press <span class="font-bold underline">Enter</span> 	&#9166;</a>
      </template>
    </div>
    <div v-else class="mt-10 flex items-center gap-x-5">
      <button class="bg-fau-blue-bright text-fau-white opacity-10 text-xl px-7 py-3" type="button" ref="button" href="#" disabled>
        <span v-if="question.type === QuestionType.SectionBreak">Start</span>
        <span v-else-if="showSkip()">Skip</span>
        <span v-else>Next</span>
      </button>
    </div>
    <!--Input Error Alert-->
    <div v-if="showInvalid()" class="f-invalid text-fau-red py-4" role="alert" aria-live="assertive">{{ errorMessage }}</div>
  </div>
</template>

<script>
/*******************************************************************
  This is using a composable from orginal repo and overriding some components to use our components
****************************************************************************/
import SectionBreakType from "./QuestionTypes/SectionBreakType.vue"
import TextTypeVue from "./QuestionTypes/TextType.vue"
import MultipleChoiceVue from "./QuestionTypes/MultipleChoiceType.vue"
import FileType from "./QuestionTypes/FileType.vue"
import DateType from "./QuestionTypes/DateType.vue"
import NumberType from "./QuestionTypes/NumberType.vue"
import EmailType from "./QuestionTypes/EmailType.vue"
import DropDownType from "./QuestionTypes/DropdownType.vue"
import LongTextType from "./QuestionTypes/LongTextType.vue"

import logic from "@ditdot-dev/vue-flow-form/src/composables/FlowFormQuestion"

logic.components["FlowFormSectionBreakType"] = SectionBreakType
logic.components["FlowFormTextType"] = TextTypeVue
logic.components["FlowFormMultipleChoiceType"] = MultipleChoiceVue
logic.components["FlowFormFileType"] = FileType
logic.components["FlowFormDateType"] = DateType
logic.components["FlowFormNumberType"] = NumberType
logic.components["FlowFormEmailType"] = EmailType
logic.components["FlowFormDropDownType"] = DropDownType
logic.components["FlowFormLongTextType"] = LongTextType

export default logic
</script>

<style scoped>
.chat-bubble {
  position: relative;
  filter: drop-shadow(4px 4px 0px #CFCFCF);
}
.chat-bubble::before {
  content: "";
  position: absolute;
  display: block;
  bottom: -20px;
  left: 0;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 20px 20px 0 0;
  border-color: #FFF transparent transparent transparent;
  transform: rotate(0deg);
}
.chat-bubble a {
  text-decoration: underline !important;
}
</style>