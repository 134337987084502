<template>
	<button class="px-2 py-1 md:px-4 md:py-2 border rounded-full border-fau-blue-bright 
	                text-fau-blue-bright text-sm" @click="signOut">Sign Out</button>
</template>

<script setup lang="ts">
import Auth from '@/modules/Auth/Auth';
import { inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const router = useRouter()
const route = useRoute()
const auth = inject('auth') as Auth

function signOut() {
  auth.SignOut().then(() => {
        router.push({
        path: "/login",
        query: { redirect: route.path }
        })
  });
}
</script>
