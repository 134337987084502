import QuestionModel, { QuestionType } from "@ditdot-dev/vue-flow-form/src/models/QuestionModel.js";
import { BaseQuestion } from "../BaseQuestion";
import QuestionConverter from "./QuestionConverter";

export default class NumberQuestionConverter extends QuestionConverter {
  convert(q: BaseQuestion) : any {
    return new QuestionModel({
      id: q.id,
      tagline: q.tagline,
      title: q.title,
      type: QuestionType.Number,
      required: q.required,
      placeholder: q.placeholder,
      mask: q.mask,
      jump: q.jump,
      description: q.description,
      min: q.min,
      max: q.max,
    });
  }
}
