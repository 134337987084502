<template>
  <ul 
  class="w-full flex flex-col md:flex-row md:flex-wrap gap-4 justify-start mb-16"
  v-bind:class="{'f-multiple': question.multiple}" role="listbox">
      <li
        v-for="(option, index) in question.options"
        class="text-fau-blue-bright"
        v-on:click.prevent="toggleAnswer(option)"
        v-bind:class="{
          'bg-fau-blue-bright option-selected': option.selected,
          'text-white': option.selected,
        }"
        v-bind:key="'m' + index"
        v-bind:aria-label="getLabel(index)"
        role="option"
      >
        <span class="f-image" v-if="hasImages && option.imageSrc">
          <img v-bind:src="option.imageSrc" v-bind:alt="option.imageAlt">
        </span>
        <div class="flex">
          <span class="font-bold underline underline-offset-4 py-2 pr-4">{{ getToggleKey(index) }}</span>
          <span v-if="option.choiceLabel()" class="f-label border-2 border-fau-blue-bright py-2 px-4">{{ option.choiceLabel() }}</span>
        </div>
      </li>
      <!--Handle Other-->
      <li
        class="text-fau-blue-bright py-2"
        v-if="!hasImages && question.allowOther"
        v-on:click.prevent="startEditOther"
        v-bind:class="{
          'border-fau-blue-bright': question.other,
          'text-fau-blue': question.other,
          'f-focus': editingOther}"
        v-bind:aria-label="language.ariaTypeAnswer"
        role="option"
      >
        <div >
          <span class="font-bold underline underline-offset-4 py-2 pr-4" v-if="!editingOther">{{ getToggleKey(question.options.length) }}</span>
          <input
            v-if="editingOther"
            v-model="question.other"
            class="textf-fau-text-black py-2 px-4"
            type="text"
            ref="otherInput"
            v-on:blur="stopEditOther"
            v-on:keyup.enter.prevent="stopEditOther"
            v-on:keyup="onChangeOther"
            v-on:change="onChangeOther"
            maxlength="256"
          />
          <span v-else-if="question.other" class="bg-fau-blue-bright text-white py-2 px-4">{{ question.other }}</span>
          <span v-else class="f-label border-2 border-fau-blue-bright py-2 px-4">Other</span>
        </div>
      </li>
    </ul>
</template>

<script>
/*******************************************************************
  This is using a composable from orginal repo
****************************************************************************/

  import logic from '@ditdot-dev/vue-flow-form/src/composables/QuestionTypes/MultipleChoiceType'

  export default logic

</script>

<style scoped>
.option-selected {
  padding-left: 1em;
}
</style>