<template>
  <div id="FlowFormContainer" 
        class="flex flex-col">
    <!--Navigation-->
    <div v-if="navigation && activeQuestionIndex != 0 && !submitted" class="my-8 md:w-2/3">
      <a class="text-fau-blue-bright text-lg font-light" href="#" v-on:click.prevent="goToPreviousQuestion()" role="button"
        v-bind:aria-label="language.ariaPrev">
        &larr;<span aria-hidden="true"> Back</span>
      </a>
    </div>
    <!--Questions-->
    <flow-form-question :ref="setQuestionRef" v-for="(q, index) in questionList" v-bind:question="q"
      v-bind:language="language" v-bind:tokens="extraTokens" v-bind:key="'q' + index"
      v-bind:active="q.index === activeQuestionIndex" v-model="q.answer" v-on:answer="onQuestionAnswered"
      v-bind:reverse="reverse" v-bind:disabled="disabled" v-on:disable="setDisabled" v-bind:autofocus="autofocus" />

    <!-- Submit Form Screen -->
    <div v-if="isOnLastStep" class="animate-[fadeIn_1s_ease-in-out]">
      <slot name="complete" :submit="submit" :isMobile="isMobile" :answeredQuestions="questionListActivePath"></slot>
    </div>
  </div>
</template>


<script>
/*******************************************************************
  This is using a composable from orginal repo and overriding some components to use our components
****************************************************************************/
import logic from "@ditdot-dev/vue-flow-form/src/composables/FlowForm"
import FlowFormQuestion from "./FlowFormQuestion"

logic.components["FlowFormQuestion"] = FlowFormQuestion

export default logic



</script>