import { doc } from "firebase/firestore";
import Auth from "../Auth";
import { AuthError } from "../AuthError";
import DBCollections from "../DBCollections";

const DB_STORED_SUBMISSION_COUNT = 5;

export interface FetchOptions {
    AuthEnabled: boolean;
}

export interface FormMeta {
    displayName: string;
    requiresAuth: boolean;
    live: boolean;
    redirect: string;
}

const getBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            var result = reader.result?.toString();

            if (result != null) {
                var parts = result.split(";");

                var data = {
                    "$content-type": parts[0].split(":")[1],
                    $content: parts[1].split(",")[1],
                };

                resolve(data);
            } else {
                reject("File is null...");
            }
        };
        reader.onerror = (error) => reject(error);
    });
};

async function ConvertFileListAnswerIntoBase64Object(fileList: FileList) {
    var base64Files = [];

    for (let i = 0; i < fileList.length; i++) {
        const file = fileList.item(i);

        base64Files.push({
            name: file?.name,
            type: file?.type,
            size: file?.size,
            content: file ? await getBase64(file) : null,
        });
    }

    return base64Files;
}

export default class FormAPI {
    SERVERURL: string;
    _auth: Auth;
    _db: DBCollections | null;

    constructor(auth: Auth, db: DBCollections | null = null) {
        this.SERVERURL =
            process.env.VUE_APP_BACKEND_URL || "http://localhost:3000";

        if (!process.env.VUE_APP_BACKEND_URL) {
            console.error(
                "The backurl was not provided... Did we forgot something?"
            );
        }

        this._auth = auth;
        this._db = db;
    }
    private async FetchFormData(
        token: string | null,
        form: string
    ): Promise<any> {
        var headers: Record<string, string> = {};

        if (token) {
            headers["Authorization"] = `Bearer ${token}`;
        }

        var requestOptions = {
            headers: headers,
            method: "GET",
        };

        var formData = await fetch(
            `${this.SERVERURL}/form/${form}`,
            requestOptions
        );

        if (formData.status == 400) {
            throw new AuthError({
                name: "BAD_REQUEST",
                message: "Bad Request",
            });
        }

        if (formData.status == 401) {
            var res = await formData.json();

            if (res.error == "NOT_AUTHORIZED_FAU") {
                throw new AuthError({
                    name: "NOT_AUTHORIZED_FAU",
                    message: "User is not using a fau account",
                });
            }

            throw new AuthError({
                name: "NOT_AUTHORIZED",
                message: "Is the user sign correctly?",
            });
        }

        if (formData.status == 404) {
            throw new AuthError({
                name: "NOT_FOUND",
                message: "Form could not be found..",
            });
        }

        return await formData.json();
    }

    private async FetchFormMeta(form: string): Promise<FormMeta> {
        var formMeta = await fetch(`${this.SERVERURL}/form/${form}/meta`, {
            method: "GET",
        });

        if (formMeta.status == 400) {
            throw new AuthError({
                name: "BAD_REQUEST",
                message: "Bad Request",
            });
        }

        if (formMeta.status == 404) {
            throw new AuthError({
                name: "NOT_FOUND",
                message: "Form could not be found..",
            });
        }

        return await formMeta.json();
    }

    private async SubmitFormData(
        token: string | null,
        form: string,
        data: any
    ): Promise<any> {
        var headers: Record<string, string> = {};

        if (token) {
            headers["Authorization"] = `Bearer ${token}`;
        }

        headers["Content-Type"] = "application/json";

        // Convert the types of the answers
        for (let i = 0; i < data.length; i++) {
            const q = data[i];

            if (q.answer == null) continue;

            switch (q.answer.constructor) {
                // Convert the file list into a base64 object
                case FileList:
                    q.answer = await ConvertFileListAnswerIntoBase64Object(
                        q.answer
                    );
                    break;

                default:
                    break;
            }
        }

        var requestOptions = {
            headers: headers,
            method: "POST",
            body: JSON.stringify(data),
        };

        var formData = await fetch(
            `${this.SERVERURL}/form/${form}`,
            requestOptions
        );

        var submissionData: {
            form: string;
            response: any;
        } = { form: form, response: await formData.json() };

        try {
            if (this._db) {
                var user = await this._auth.GetCurrentUser();

                var email = user?.email || "Anonymous";

                var timestamp = new Date().toISOString();

                await this._db.SetDocument(
                    `users/${email}/submissions/`,
                    timestamp,
                    submissionData
                );

                var formSubmissions = await this._db.GetCollectionItems(
                    `users/${email}/submissions`
                );

                if (formSubmissions.size > DB_STORED_SUBMISSION_COUNT) {
                    var firstDoc = formSubmissions.docs[0];
                    await this._db.DeleteCollectionItem(
                        `users/${email}/submissions`,
                        firstDoc.id
                    );
                    console.warn("Deleted the oldest submission");
                }
            } else {
                console.warn(
                    "No database connection was provided. The submission was not stored."
                );
            }
        } catch (error) {
            console.error("Failed to store the submission in the database");
            console.error(error);
        }

        switch (formData.status) {
            case 400:
                throw new AuthError({
                    name: "BAD_REQUEST",
                    message: "Bad Request",
                });
            case 401:
                var res = await formData.json();

                if (res.error == "NOT_AUTHORIZED_FAU") {
                    throw new AuthError({
                        name: "NOT_AUTHORIZED_FAU",
                        message: "User is not using a fau account",
                    });
                }

                throw new AuthError({
                    name: "NOT_AUTHORIZED",
                    message: "Is the user sign correctly?",
                });
            case 404:
                throw new AuthError({
                    name: "NOT_FOUND",
                    message: "Form could not be found..",
                });
            default:
                console.log("Submission was successful");
                break;
        }

        return submissionData;
    }

    async GetFormData(form: string) {
        var token: string | null = await this._auth.GetToken();
        return await this.FetchFormData(token, form);
    }

    async GetFormMeta(form: string): Promise<FormMeta> {
        return await this.FetchFormMeta(form);
    }

    async SubmitForm(formID: string, questions: any) {
        var token: string | null = await this._auth.GetToken();
        return await this.SubmitFormData(token, formID, questions);
    }
}
