import { createApp } from "vue";
import App from "./App.vue";
import { createPinia } from "pinia";
import "./assets/tailwind.css";
import Auth from "./modules/Auth/Auth";
import router from "./router";
import DBCollections from "./modules/Auth/DBCollections";

const pinia = createPinia();
const auth = new Auth();
const db = new DBCollections(auth);

var app = createApp(App);

app.use(pinia);
app.use(router);


app.use(auth);
app.use(db);


app.mount("#app");
