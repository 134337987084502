import { BaseQuestion } from "./FormQuestionHandling/BaseQuestion";

export default class Section {
    id : string;
    name : string;
    image : string;
    lower: number;
    upper: number;
    progress : number;

    constructor(id: string, name: string, image: string) {
        this.id = id;
        this.name = name;
        this.image = image;
        this.lower = -1;
        this.upper = -1;
        this.progress = 0;
    }

    public CalculateUpperAndLowerBounds( questions : BaseQuestion[]) : Section{
        this.lower = questions.findIndex((q) => {
            return q.section_id === this.id
        });
        

        if(questions[this.lower].type === "sectionbreak"){
            this.lower++
        }
        
        for (let i = this.lower; i < questions.length; i++) {
            if(i + 1 >= questions.length){
                this.upper = questions.length - 1;
                return this;
            }

            const next_q = questions[i + 1];
            if(next_q.section_id != this.id){
                this.upper = i;
                return this;
            }
        }

        return this;
    }

    public CalculateProgress(questionIndex: number){
        if(questionIndex < this.lower){ 
            this.progress = 0;
            return this.progress;
        }
        
        if(questionIndex > this.upper) {
            this.progress = 1;
            return this.progress;
        }
        //console.log(questionIndex)
        var n = questionIndex - this.lower ;
        var d = this.upper - this.lower + 1;

        this.progress = n/d;
        return this.progress;
    }

} 