<template>
    <!-- TODO: Make this look better in mobile -->
    <div class="flex flex-col h-screen">
        <div class="flex flex-col items-center justify-center m-5 flex-grow">
            <h1 class="text-4xl  mb-4 text-center text-fau-blue-bright font-light">Incorrect Account</h1>
            <p class="mb-4">
                You have signed in with a non-FAU account. Please sign in with your
                FAU account.
            </p>
            <p class="mb-4" v-if="user">
                You are currently signed in as <strong>{{ user?.email }}</strong>.
            </p>
            <button
                @click="switchAccount"
                class="bg-fau-blue-bright text-fau-white text-xl px-7 py-3"
            >
                Switch Accounts
            </button>
            <p class="mt-4">
                If you continue to experience issues, please contact
                <a
                    href="mailto:idesign@fau.edu?subject=Login%20Issue%20-%20Non-FAU%20Account"
                    style="color: revert;"
                    >idesign@fau.edu</a
                >.
            </p>
        </div>
        <Footer />
    </div>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from "vue-router";
import Auth from "@/modules/Auth/Auth";
import Footer from './Footer.vue'
import { inject, onMounted, ref } from "vue";
import { User } from "firebase/auth";

const router = useRouter();
const route = useRoute();
const auth = inject("auth") as Auth;

function switchAccount() {
    auth.SignOut().then(() => {
        router.push({
            path: "/login",
            query: route.query,
        });
    });
}

const user = ref<User|null>();  
const emit = defineEmits(['is-page-loaded']);
emit('is-page-loaded', false);

onMounted(() => {

    auth.GetCurrentUser().then((u : User | null) => {
        //console.log(u);
        user.value = u;
    }).finally(() => {
        emit('is-page-loaded', true);
    });
});
</script>
