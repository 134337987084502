<template>
  <div class="h-screen w-full flex flex-col relative">
    <div id="sign-out" v-if="user" class="absolute right-0 top-0 p-8 z-10">
      <SignoutButton/>
    </div>
    <div v-if="!loading" class="flex-grow flex flex-col relative">
      <!-- Flow Form -->
      <div class="w-fit px-8 flex flex-grow  md:w-2/3 md:min-w-[700px] 
          md:px-16 md:mx-13 min-h-[50vh]">
        <FlowForm v-bind:questions="form.flowFormQuestions" v-bind:language="form.language" v-bind:extraTokens="form.extraTokens"
          @answer="onAnswer" @onShowQuestion="onShowQuestion" @submit="OnSubmit" @completed="form.onCompleted()">
          <template #complete="{submit , isMobile,answeredQuestions}">
            <!--NOT SUBMITED-->
            <template v-if="form.submitStatus == SubmitStatus.WAITING_FOR_USER">
              <SubmitPreviewScreen :answered-questions="answeredQuestions" :is-mobile="isMobile" :submit="submit" />
            </template>
            <!--SUBMITING-->
            <template v-else-if="form.submitStatus == SubmitStatus.PENDING">
              <SubmitingScreen />
            </template>
            <!--FAILED-->
            <template v-else-if="form.submitStatus == SubmitStatus.FAILURE">
              <SubmitFailedScreen :is-mobile="isMobile" :submit="submit" />
            </template>
            <!--SUCCESS-->
            <template v-else-if="form.submitStatus == SubmitStatus.SUCCESS">
              <SubmitSuccess />
            </template>
          </template>
        </FlowForm>
      </div>
      <!-- Section Image -->
      <div id="bg-illustration z-0"
        class="absolute right-8 bottom-24 w-[150px] md:right-24 md:w-[30vh] md:min-w-[100px] md:max-h-[300px]" :class="{
          'hidden md:block md:opacity-20 md:w-[20vh]': form.currentQuestion.type !== 'sectionbreak'
        }">
        <img :src="form.CurrentSectionImageURL" class="w-full" alt="">
      </div>
      <!-- progress bar -->
      <div class="w-full progress-bar-container">
        <div class="md:w-2/3">
          <ProgressBar :_sections="form.sections" :currentQuestionIndex="form.currentQuestionIndex" :submitStatus="form.submitStatus" />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script setup lang="ts">
import FlowForm from "@/extends/FlowForm.vue";
import { inject, onMounted, ref} from "vue";
import Footer from './Footer.vue'
import ProgressBar from './components/ProgressBar.vue'
import Form, { SubmitStatus } from "@/modules/Form/Form";
import QuestionModel from "vue-flow-form/src/models/QuestionModel";
import SubmitPreviewScreen from './components/SubmitPreviewScreen.vue'
import SubmitingScreen from './components/SubmitingScreen.vue'
import SubmitFailedScreen from './components/SubmitFailedScreen.vue'
import SubmitSuccess from './components/SubmitSuccess.vue'
import SignoutButton from './components/SignoutButton.vue'
import { User } from "firebase/auth";
import Auth from "@/modules/Auth/Auth";
import DBCollections from "@/modules/Auth/DBCollections";

const props = defineProps({
  id: {
    type: String,
    required: true
  },
})

const emit = defineEmits(['is-page-loaded']);

const auth = inject('auth') as Auth;
const db = inject('dbcollection') as DBCollections;

const user = ref<User|null>();

var loading = ref(true);

const form = ref<Form>(new Form(props.id,auth, db))

function onShowQuestion(question: QuestionModel) {
  form.value.setCurrentQuestionTo(question)
}

function onAnswer(questionAnswered: QuestionModel) {

}

async function OnSubmit(questionList: QuestionModel[]) {
  form.value.submitForm(questionList);
}
emit('is-page-loaded', false);
// only on client side
onMounted(() => {

  auth.GetCurrentUser().then((u : User | null) => {
    user.value = u;
  });
  
  form.value.Build()
    .then(() => {
      loading.value = false;
      
      emit('is-page-loaded', true);
    })
    .catch(e => {
      console.error(e)
      alert(e.message)
    });
})

</script>

<style>
.chat-bubble a {
  text-decoration: underline;
  color: #003366;
}
</style>@/modules/Form/Form