<template>
  <!--this is overriding the super component-->
  <span
  v-bind:data-placeholder="inputType === 'date' ? placeholder : null">
    <input
      class="border-b-2 border-[#0073E6] text-xl p-4 text-fau-blue-bright"
      ref="input"
      v-bind:type="inputType"
      v-bind:value="modelValue"
      v-bind:required="question.required"
      v-on:keydown="onKeyDown"
      v-on:keyup="onChange"
      v-on:keyup.enter.prevent="onEnter"
      v-on:keyup.tab.prevent="onEnter"
      v-on:focus="setFocus"
      v-on:blur="unsetFocus"
      v-bind:min="question.min"
      v-bind:max="question.max"
      v-on:change="onChange"
      v-bind:placeholder="placeholder"
      v-bind:maxlength="question.maxLength"
    />
  </span>
</template>

<script>
  /*******************************************************************
    This is using a composable from orginal repo and overriding some components to use our components
  ****************************************************************************/
 
  import logic from '@ditdot-dev/vue-flow-form/src/composables/QuestionTypes/DateType'
  import TextType from './TextType';

  logic.extends = TextType // this is pulling the script

  export default logic
</script>

<style scoped>
input[type="date"]::-webkit-calendar-picker-indicator {
  background-image: url("data:image/svg+xml,%3Csvg width='72' height='80' viewBox='0 0 72 80' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M64 8H60V0H52V8H20V0H12V8H8C3.56 8 0.04 11.6 0.04 16L0 72C0 76.4 3.56 80 8 80H64C68.4 80 72 76.4 72 72V16C72 11.6 68.4 8 64 8ZM64 72H8V32H64V72ZM24 48H16V40H24V48ZM40 48H32V40H40V48ZM56 48H48V40H56V48ZM24 64H16V56H24V64ZM40 64H32V56H40V64ZM56 64H48V56H56V64Z' fill='%230073E6'/%3E%3C/svg%3E%0A");
}
</style>