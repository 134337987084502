<template>
  <div class="f-content" v-if="question.content">
    <span class="f-section-text" v-html="question.content"></span>
  </div>
</template>

<script>
/*******************************************************************
  This is using a composable from orginal repo 
****************************************************************************/

  import logic from '@ditdot-dev/vue-flow-form/src/composables/QuestionTypes/SectionBreakType'

  export default logic
</script>