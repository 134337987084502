import { ChoiceOption } from "@ditdot-dev/vue-flow-form/src/models/QuestionModel.js"

abstract class DataSourceOptions{
    abstract getOptions(data: any): any;
}

class BasicOptions extends DataSourceOptions{
    getOptions(data: any){
        return data.map((k : any)=> new ChoiceOption({
            label: k, 
            value: k
          }))
    }
}

class SemesterOptions extends DataSourceOptions{
    getOptions(data: any){
        return data.map((k : any)=> new ChoiceOption({
            label: k.name + " " + k.year, 
            value: k.name + " " + k.year
          }))
    }
}

class DeanOptions extends DataSourceOptions{
    getOptions(data: any){
        return data.map((k : any)=> new ChoiceOption({
            label: k.name, 
            value: k
          }))
    }
}

class DataSourceOptionsFactory {

    factories: Record<string, Record<string,DataSourceOptions>>;

    constructor(factories: Record<string, Record<string,DataSourceOptions>>){
        this.factories = factories;
    }

    getOptions(type: string, call: string, data: any){
        if(!this.factories[type] || !this.factories[type][call])
            throw new Error("The data source factory does not exist for the given type and call");

        return this.factories[type][call].getOptions(data);
    }
}

const basicOptions = new BasicOptions();

export default new DataSourceOptionsFactory({
    "apiCall" : {
        "/college/${college}/department" : basicOptions,
        "/college/dean" : new DeanOptions(),
        "/college" : basicOptions,
        "/semesters" : new SemesterOptions(),
        "/degree/${type}" : basicOptions,
        "/degree/" : basicOptions,
        "/v2/degree/${college}" : basicOptions,
        "/v2/degree/${college}/${degree}" : basicOptions,
    }
});