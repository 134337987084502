import { BaseQuestion } from "../BaseQuestion";
import BasicAPI from "../../../Auth/APIs/BasicAPI";
import QuestionConverter from "./QuestionConverter";
import QuestionModel, { ChoiceOption, QuestionType } from "@ditdot-dev/vue-flow-form/src/models/QuestionModel.js";
import DataSourceOptionFactory from "../DataSourceHandler/DataSourceOptionFactory";

export default class MultipleChoiceQuestionConverter extends QuestionConverter {
  private _basicAPI : BasicAPI;

  get basicAPI() {
    return this._basicAPI;
  }

  constructor(basicAPI : BasicAPI){
    super();
    this._basicAPI = basicAPI;
  }

  UpdateTextFromAnswers(str: string, questions : any[]){
    return str.replaceAll(/\${(.*?)}/g, (match, key) => {
        var id = key.trim()
  
        for (let i = 0; i < questions.length; i++) {
          const q = questions[i];
          if(q.type == QuestionType.SectionBreak) continue;
  
          if(q.id.trim() === id){
            return q.answer
          }
        }
  
        return  match
    });
  }

  convert(q: BaseQuestion): any {
    var options = [
      new ChoiceOption({
        label: "Loading",
        value: "Loading"
      })
    ];
    if (q.options) {
      options = q.options.map((op) => new ChoiceOption({
        label: op.label,
        value: op.value
      }));
    }
  
    var model = new QuestionModel({
      id: q.id,
      tagline: q.tagline,
      title: q.title,
      type: QuestionType.MultipleChoice,
      required: q.required,
      options: options,
      helpText: q.helpText,
      jump: q.jump,
      allowOther: q.allowOther,
      description: q.description
    });
  
    if (q.useDataSourceForOptions) {
      var me = this;
      model.onShow = async function (questions: any) {
  
        var dataSource = q.dataSource;
  
        switch (dataSource?.type) {
          case "apiCall":
            var transformedCall = me.UpdateTextFromAnswers(dataSource?.name, questions);
            console.log(transformedCall);
            var data = await me.basicAPI.GetData(transformedCall, dataSource.query);
            console.log(data);
            this.options = DataSourceOptionFactory.getOptions(dataSource?.type,dataSource?.name,data);
            break;
          default:
            this.options = [
              new ChoiceOption({
                label: "DataSource Type Not Implemented",
                value: "DataSource Type Not Implemented"
              })
            ];
        }
      };
    }
  
    return model;
  }
}