<template>
    <div
        class="animate-[fadeIn_1s_ease-in-out] flex flex-col items-center justify-center flex-grow absolute left-8 right-8 top-8 bottom-8"
    >
        <div
            class="bg-fau-blue-bright w-4 h-4 m-8 rounded-full animate-bounce"
        ></div>
        <p class="text-fau-blue-bright text-2xl">Submitting...</p>
    </div>
</template>
