import FormAPI from "@/modules/Auth/APIs/FormAPI";
import { BaseQuestion } from "@/modules/Form/FormQuestionHandling/BaseQuestion";
import Section from "@/modules/Form/Section";
import QuestionConverterFactory from "@/modules/Form/FormQuestionHandling/QuestionConverterFactory";
import QuestionModel from "vue-flow-form/src/models/QuestionModel";
import LanguageModel from "@ditdot-dev/vue-flow-form/src/models/LanguageModel.js";
import { MaskModel } from "@ditdot-dev/vue-flow-form/src/models/MaskModel";
import Auth from "../Auth/Auth";
import ImageAPI from "../Auth/APIs/ImageAPI";
import DBCollections from "../Auth/DBCollections";

export enum SubmitStatus {
    USER_ANSWERING = -1,
    WAITING_FOR_USER = 0,
    PENDING = 1,
    FAILURE = 2,
    SUCCESS = 3,
}

export default class Form {
    public get submitStatus(): SubmitStatus {
        return this._submitStatus;
    }

    public get flowFormQuestions(): QuestionModel[] {
        return this._flowFormQuestions;
    }

    public get sections(): Section[] {
        return this._sections;
    }

    public get currentQuestionIndex(): number {
        return this._currentQuestionIndex;
    }

    public get currentSectionIndex(): number {
        return this._currentSectionIndex;
    }

    public get currentQuestion(): BaseQuestion {
        return this._baseQuestions[this._currentQuestionIndex];
    }

    public get currentSection(): Section {
        return this._sections[this._currentSectionIndex];
    }

    public get language(): any {
        return this._language;
    }

    public get extraTokens(): MaskModel {
        return this._extraTokens;
    }

    public get CurrentSectionImageURL(): string {
        return this._imageAPI.GetImage_Sync(this.currentSection.image);
    }

    private _submitStatus: SubmitStatus;
    private _sections: Section[];
    private _formId: string;
    private _baseQuestions: BaseQuestion[];
    private _flowFormQuestions: QuestionModel[];
    private _currentQuestionIndex: number;
    private _currentSectionIndex: number;
    private _language: any;
    private _extraTokens: MaskModel;
    private _formAPI: FormAPI;
    private _imageAPI: ImageAPI;

    constructor(id: string,auth: Auth, dbCollections: DBCollections | null = null) {
        this._submitStatus = SubmitStatus.USER_ANSWERING;
        this._formId = id;
        this._baseQuestions = [];
        this._flowFormQuestions = [];
        this._sections = [];
        this._currentQuestionIndex = -1;
        this._currentSectionIndex = -1;
        this._language = new LanguageModel({});
        this._extraTokens = new MaskModel([
            {
                token: "L",
                pattern: /[a-zA-Z]/,
                optional: true,
                transform: (v: string) => v.toLocaleUpperCase(),
            },
        ]);
        this._formAPI = new FormAPI(auth, dbCollections);
        this._imageAPI = new ImageAPI();
    }

    async Build() {
        // Fetch form data
        var { sections, questions } = await this._formAPI.GetFormData(this._formId);

        // Set form data
        this._baseQuestions = questions;

        sections.forEach((s: any) => {
            var {id, name, image} = s;
            var _s = new Section(id, name, image)
                    .CalculateUpperAndLowerBounds(questions as BaseQuestion[]);
            this._sections.push(_s);
        });

        const factory = new QuestionConverterFactory();

        this._flowFormQuestions = factory.Convert(
            this._baseQuestions
        );
        this._currentQuestionIndex = 0;
        this._currentSectionIndex = 0;
        this._submitStatus = SubmitStatus.USER_ANSWERING;
    }

    async submitForm(questionList: QuestionModel[]) {
        var questionsSimple = questionList.map((q: any) => {
            return {
                index: q.index,
                id: q.id,
                answer: q.answer,
            };
        });

        this._submitStatus = SubmitStatus.PENDING;
        console.log("Submitting..");

        await this._formAPI.SubmitForm(this._formId, questionsSimple)
            .then((d) => {
                console.log("Submitted:", d);
                this._submitStatus = SubmitStatus.SUCCESS;
            })
            .catch((e) => {
                console.error(e);
                this._submitStatus = SubmitStatus.FAILURE;
            });
    }

    setCurrentQuestionTo(question: QuestionModel) {
        this._currentQuestionIndex = this.getQuestionIndex(question);
        this._currentSectionIndex = this._sections.findIndex((s) => {
            return s.id === this.currentQuestion.section_id;
        });
    }

    private getQuestionIndex(question: QuestionModel) {
        return this._baseQuestions.findIndex((q) => {
            return q.id === question.id;
        });
    }

    GetQuestion(index: number) {
        return this._baseQuestions[index];
    }

    onCompleted() {
        this._submitStatus = 0;
    }
}
