import { FirebaseApp } from "@firebase/app";
import { Firestore, getFirestore, addDoc, collection, getDocs, doc, deleteDoc, setDoc } from "firebase/firestore";
import { App } from "vue";
import Auth from "./Auth";

export default class DBCollections {
    

    private _db : Firestore;

    constructor(auth: Auth) {
        this._db = getFirestore(auth.FirebaseApp);
    }

    /**
     * Vue Plugin install function
     * @param app 
     * @param options 
     */
    install(app: App, options? : any): void {
        app.config.globalProperties.$dbcollections = this;
        app.provide('dbcollection', this);
    }

    public async AddToCollection(name: string, data: any) {
        return await addDoc(collection(this._db, name), data);
    }

    public async SetDocument(collection: string, id: string, data: any) {
        return await setDoc(doc(this._db, collection, id), data);
    }

    public async GetCollectionItems(name: string) {
        return await getDocs(collection(this._db, name));
    }

    public async DeleteCollectionItem(name: string, id: string) {
        return await deleteDoc(doc(this._db, name, id));
    }
}