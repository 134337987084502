<template>
    <div class="flex flex-col h-screen">
        <div class="flex flex-col items-center justify-center m-5 flex-grow">
            <img
                :src="APIHOST + '/images/two-folks-looking-at-target.svg'"
                class="w-1/2 md:max-w-64"
                alt=""
            />
            <h1
                class="text-4xl mb-4 text-center text-fau-blue-bright font-light"
            >
                Welcome to our
                <span class="whitespace-nowrap font-bold"
                    >{{ formMeta?.displayName }} Form</span
                >
            </h1>
            <p class="text-lg">Please sign in to continue</p>
            <div v-if="errorMessage" class="mt-4">
                <p class="text-red-500">{{ errorMessage }}</p>
            </div>
            <button
                @click="signIn"
                class="mt-4 bg-fau-blue-bright text-white px-4 py-2 rounded-md"
            >
                Sign in with your FAU account
            </button>
        </div>
        <Footer />
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, inject } from "vue";
import { useRoute, useRouter } from "vue-router";
import Auth from "@/modules/Auth/Auth";
import FormAPI, { FormMeta } from "@/modules/Auth/APIs/FormAPI";
import Footer from "./Footer.vue";

const emit = defineEmits(["is-page-loaded"]);
emit("is-page-loaded", false);

const APIHOST: string = process.env.VUE_APP_BACKEND_URL;

const router = useRouter();
const route = useRoute();
const auth = inject("auth") as Auth;

const errorMessage = ref<string>();

const formMeta = ref<FormMeta>();

function signIn() {
    auth.SignInWithRedirect();
}

function LoadLoginForm() {
    var formId: string | undefined = route.query.redirect?.toString();

    if (formId == undefined) {
        router.push({
            path: "/",
        });
        return;
    }
    // remove the first character as it's a '/'
    formId = formId.substring(1, formId.length);

    
    const formAPI = new FormAPI(auth);

    formAPI.GetFormMeta(formId)
        .then((result) => {
            //console.log(result)

            formMeta.value = result;
            emit("is-page-loaded", true);
        })
        .catch((error) => {
            console.error("Failed to get form meta", error);
            errorMessage.value = error.message;
        });
}

// only on client side
onMounted(() => {    
    auth.HandleRedirect()
        .then((result) => {
            if(!result){
                LoadLoginForm();
                return;
            }
            console.log("Redirect handled", result);
            router.push({
                    path: route.query.redirect?.toString() ?? "",
                });
        })
        .catch((error) => {
            console.error("Failed to handle redirect", error);
            errorMessage.value = error.message;
            LoadLoginForm();
        });   
});
</script>
@/modules/Auth/APIs/FormAPI