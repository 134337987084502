<template>
    <div class="w-full h-full relative">
        <div
            v-if="loading"
            class="overflow-hidden h-full w-full md:flex md:justify-center md:items-center absolute z-10"
        >
            <div class="w-full aspect-box relative">
                <div
                    ref="loader"
                    class="relative h-full w-full"
                    :class="{
                        'animate-[grow_0.5s_ease-in-out_infinite]': isAnimating,
                        'animate-[breathe_1s_linear_infinite]': !isAnimating,
                    }"
                >
                    <img src="./../assets/circle.svg" />
                </div>
            </div>
        </div>
        <div
            class="h-full w-full top-0 absolute z-0"
            :class="{
                'animate-[fadeIn_2s_ease-in-out]': loading,
                hidden: !showElement,
            }"
        >
            <slot></slot>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";

const loading = ref(true);
const isAnimating = ref(false);
const showElement = ref(false);

const props = defineProps({
    isPageLoaded: {
        type: Boolean,
        required: true,
    },
});

async function Load() {
    loading.value = true;
    showElement.value = true;

    //await new Promise(r => setTimeout(r, 5000)); // fake load

    isAnimating.value = true;
    await new Promise((r) => setTimeout(r, 400));

    isAnimating.value = false;

    loading.value = false;
}

function reset() {
    loading.value = true;
    isAnimating.value = false;
    showElement.value = false;
}

watch(
    () => props.isPageLoaded,
    (newValue, oldValue) => {
        if (newValue == true) {
            Load();
        } else reset();
    }
);
</script>

<style></style>
