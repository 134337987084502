<template>
  <div class="h-full w-full bg-fau-blue-light overflow-auto">
    <loader :is-page-loaded="isPageLoaded">
      <router-view @is-page-loaded="SetPageLoaded" />
    </loader>
  </div>
</template>

<script setup lang="ts">
import loader from "@/views/loader.vue"
import { ref } from "vue";

const isPageLoaded = ref(false);

function SetPageLoaded(f: boolean) {
  isPageLoaded.value = f;
}

</script>

<style>
#app {
  height: 100%;
  width: 100%;
  position: absolute;
}
</style>