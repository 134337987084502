<template>
    <div class="mb-8 text-fau-blue-bright">
        <h1 class="text-4xl">Almost there...</h1>
        <p class="text-fau-silver text-lg">
            Please
            <span
                class="underline underline-offset-4 decoration-2 decoration-fau-red"
                >review</span
            >
            your answers before submitting!
        </p>
    </div>
    <div>
        <ul>
            <li
                v-for="(q, index) in answeredQuestions.slice(
                    answeredQuestions.length > 1 ? 1 : 0
                )"
                :key="index"
                class="mb-4 pb-4 border-b border-b-fau-gray"
            >
                <p class="text-fau-blue-bright text-lg mb-2" v-html = "q.title"></p>
                <p class="text-fau-blue text-2xl">
                    <template v-if="q.options && q.options.length > 0">
                        {{ getLabelFromAnswer(q.options, q.answer) }}
                    </template>
                    <!-- Resolve for files-->
                    <template v-else-if="q.type == 'FlowFormFileType'">
                        {{ FileNameFromAnswer(q.answer) }}
                    </template>
                    <template v-else>
                        {{ q.answer }}
                    </template>
                </p>
            </li>
        </ul>
    </div>
    <div class="flex items-center gap-x-5 text-fau-blue mt-8">
        <button
            class="bg-fau-blue-bright text-white p-5"
            ref="button"
            type="button"
            href="#"
            v-on:click.prevent="submit"
        >
            <span>Press to Submit</span>
        </button>
        <template v-if="!isMobile">
            <div class="border border-black w-10 h-0"></div>
            <a href="#" v-on:click.prevent="submit">Press Enter</a>
        </template>
    </div>
</template>

<script setup lang="ts">
defineProps<{
    answeredQuestions: any[];
    isMobile: boolean;
    submit: () => void;
}>();

function getLabelFromAnswer(options: any, answer: any) {
    return options.filter((o: any) => o.value == answer)[0].label;
}

function FileNameFromAnswer(answer: FileList) {
    var fileNames = [];
    for (let i = 0; i < answer.length; i++) {
        const file = answer.item(i);

        fileNames.push(file?.name);
    }
    return fileNames.join(",");
}
</script>
