<template>
  <span v-bind:data-placeholder="inputType === 'date' ? placeholder : null">
    <!--If you have a mask, use the-mask component-->
    <template v-if="question.mask">
      <the-mask class="border-b-2 border-fau-blue-bright bg-transparent px-2 py-4 text-fau-blue" ref="input" v-bind:mask="question.mask" v-bind:masked="false"
        v-bind:tokens="tokens.Build()" v-bind:type="inputType" v-bind:value="modelValue"
        v-bind:required="question.required" v-on:keydown="onKeyDown" v-on:keyup="onChange" v-on:focus="setFocus"
        v-on:blur="unsetFocus" v-on:keyup.enter.prevent="onEnter" v-on:keyup.tab.prevent="onEnter"
        v-bind:placeholder="placeholder" v-bind:min="question.min" v-bind:max="question.max" v-on:change="onChange" />
    </template>
    <template v-else>
      <input ref="input" class="border-b-2 border-fau-blue-bright bg-transparent px-2 py-4 text-fau-blue" v-bind:type="inputType" v-bind:value="modelValue"
        v-bind:required="question.required" v-on:keydown="onKeyDown" v-on:keyup="onChange"
        v-on:keyup.enter.prevent="onEnter" v-on:keyup.tab.prevent="onEnter" v-on:focus="setFocus" v-on:blur="unsetFocus"
        v-bind:min="question.min" v-bind:max="question.max" v-on:change="onChange" v-bind:placeholder="placeholder"
        v-bind:maxlength="question.maxLength" />
    </template>
  </span>
</template>

<script>
/*******************************************************************
  This is using a composable from orginal repo and overriding some components to use our components
****************************************************************************/

import logic from '@ditdot-dev/vue-flow-form/src/composables/QuestionTypes/TextType'

export default logic
</script>
