/<template>
  <div class="flex items-center justify-center h-screen bg-gray-100">
    <div class="text-center">
      <h1 class="text-6xl font-bold text-gray-800 mb-4">404</h1>
      <h2 class="text-2xl font-semibold text-gray-700 mb-4">Page Not Found</h2>
      <p class="text-lg text-gray-600">The page you are looking for does not exist.</p>
    </div>
  </div>
</template>

<script setup lang="ts">
    const emit = defineEmits(['is-page-loaded']);
    emit('is-page-loaded', true);
</script>