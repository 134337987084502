import QuestionModel, { QuestionType } from "@ditdot-dev/vue-flow-form/src/models/QuestionModel.js";
import { BaseQuestion } from "../BaseQuestion";
import QuestionConverter from "./QuestionConverter";


export default class UploadQuestionConverter extends QuestionConverter {
  convert(q: BaseQuestion) : any {
    return new QuestionModel({
      id: q.id,
      tagline: q.tagline,
      title: q.title,
      type: QuestionType.File,
      required: q.required,
      jump: q.jump,
      accept: q.accept,
      maxSize: q.maxSize,
      multiple: q.multiple,
      min: q.min,
      max: q.max,
      description: q.description
    });
  }
}
