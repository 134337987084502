<template>
    <div
        id="footer-progress"
        class="w-full px-8 md:pl-16 py-8 flex gap-4"
        v-bind:class="{
            'not-started': submitStatus== SubmitStatus.USER_ANSWERING,
            completed: submitStatus== SubmitStatus.WAITING_FOR_USER,
        }"
    >
        <div
            class="rounded-full md:flex text-center text-xs text-fau-blue-bright hidden md:border border-fau-blue-bright h-10 w-10 justify-center items-center"
        >
			<div v-if="submitStatus==0">100%</div>
            <div v-else>{{ CalculateProgress(currentQuestionIndex) }}%</div>
        </div>
        <!-- Need Section Logic Here -->
        <div
            id="progress-bar-mobile"
            class="flex w-full md:hidden justify-between items-center text-fau-blue-bright uppercase text-xs"
        >
            <template v-for="section in _sections">
                <div
                    v-if="GetCurrentSection(currentQuestionIndex)?.name == section.name"
                    :key="section.id"
                    class="mx-2 px-4 py-2 rounded-full border border-fau-blue-accent transition-all"
                >
                    <span v-if="submitStatus == SubmitStatus.USER_ANSWERING">{{ section.name }}</span>
                    <span v-if="submitStatus == SubmitStatus.PENDING">&#8613;</span>
                    <span v-if="submitStatus == SubmitStatus.FAILURE">&#33;</span>
                    <span v-if="submitStatus == SubmitStatus.SUCCESS">&#10003;</span>
                </div>
                <div
                    v-else
                    :key="section.lower"
                    class="w-1 h-1 flex-grow rounded-full bg-fau-blue-accent"
                ></div>
            </template>
        </div>
        <div
            id="coce-bar-desktop"
            class="justify-between flex-grow border border-fau-blue-bright text-fau-blue-bright hidden rounded-full relative md:flex font-bold uppercase text-xs md:font-sm overflow-hidden"
        >
            <div
                class="text-center relative flex-grow flex items-center justify-center z-10 border-fau-blue-bright"
                :class="{ 'border-l': i !== 0 }"
                v-for="(s, i) in _sections"
                :key="i"
            >
                {{ s.name }}
                <div v-if="submitStatus == SubmitStatus.USER_ANSWERING" 
                    class="progress h-full absolute left-0 bg-fau-blue-accent transition-all -z-50"
                    v-bind:style="'width: ' + s.progress * 100 + '%;'"
                ></div>
				<div v-else
                    class="progress h-full absolute left-0 bg-fau-blue-accent transition-all -z-50"
                    v-bind:style="'width: 100%;'"
                ></div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import Section from "@/modules/Form/Section";
import { SubmitStatus } from "@/modules/Form/Form";

const { _sections, currentQuestionIndex } = defineProps<{
    _sections: Section[];
    currentQuestionIndex: number;
    submitStatus: SubmitStatus;
}>();

function GetCurrentSection(questionIndex: number) {
	if (questionIndex == 0) {
		return _sections[0];
	}

	for (let i = 0; i < _sections.length; i++) {
		if (_sections[i].lower <= questionIndex && questionIndex <= _sections[i].upper) {
			//console.log(_sections[i]);
			return _sections[i];
		}
	}
	return null;
}

function CalculateProgress(index: number) {
    var i = index;

    var sum_p = 0;

    _sections.forEach((s) => {
        var sum_n = s.CalculateProgress(i);
        sum_p += sum_n;
    });

    //console.log(sum_p);

    return parseInt(((sum_p / _sections.length) * 100).toFixed(0));
}

onMounted(() => {});
</script>
@/modules/Form/Section@/modules/Form/Form