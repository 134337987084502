import {QuestionType,ChoiceOption} from "@ditdot-dev/vue-flow-form/src/models/QuestionModel.js"
import QuestionConverter from "./QuestionConverters/QuestionConverter"
import TextQuestionConverter from "./QuestionConverters/TextQuestion"
import EmailQuestionConverter from "./QuestionConverters/EmailQuestion"
import NumberQuestionConverter from "./QuestionConverters/NumberQuestion"
import SectionBreakConverter from "./QuestionConverters/SectionBreak"
import MultipleChoiceQuestionConverter from "./QuestionConverters/MultipleChoiceQuestion"
import DropDownQuestionConverter from "./QuestionConverters/DropDownQuestion"
import UploadQuestionConverter from "./QuestionConverters/UploadQuestion"
import LongTextQuestionConverter from "./QuestionConverters/LongTextQuestion"
import DateQuestionConverter from "./QuestionConverters/DateQuestion"
import NotImplementedQuestionConverter from "./QuestionConverters/NotImplementedQuestion"
import { BaseQuestion } from "./BaseQuestion"
import BasicAPI from "../../Auth/APIs/BasicAPI"


/*
https://www.ditdot.hr/en/docs/vue-flow-form-guide
https://github.com/ditdot-dev/vue-flow-form/tree/master
*/

export default class QuestionConverterFactory {

  questionConverters : Record<string, QuestionConverter>;
  notImplementedConverter : QuestionConverter;

  constructor(){

    const basicAPI = new BasicAPI();

    this.questionConverters = {
      "text" : new TextQuestionConverter(),
      "email" : new EmailQuestionConverter(),
      "number" : new NumberQuestionConverter(),
      "sectionbreak" : new SectionBreakConverter(),
      "multiplechoice" : new MultipleChoiceQuestionConverter(basicAPI),
      "dropdown" : new DropDownQuestionConverter(basicAPI),
      "upload" : new UploadQuestionConverter(),
      "date" : new DateQuestionConverter(),
      "longtext" : new LongTextQuestionConverter()
    };
    this.notImplementedConverter = new NotImplementedQuestionConverter();
  }

  Convert(questions: Array<BaseQuestion>) : any[] {
    var ConvertedQuestions: any[] = [];

    questions.forEach(q => {
  
      if(!q.type){
        console.error("Question type is not defined for question with id: " + q.id)
        return;
      }
  
      var converter = this.questionConverters[q.type] || this.notImplementedConverter;
  
      var convertedQuestion : any = converter.convert(q);
  
      ConvertedQuestions.push(convertedQuestion);
    });
  
    return ConvertedQuestions
  }
}