import { FirebaseApp, initializeApp } from "firebase/app";
import FirebaseAuth,{
    User,
    UserCredential,
    browserLocalPersistence,
    getAuth,
    signOut,
    signInWithPopup,
    getRedirectResult,
    signInWithRedirect,
    OAuthProvider,
} from "firebase/auth";
import { App } from "vue";
import { AuthError } from "./AuthError";

/**
 * Represents the authentication plugin.
 */
export default class Auth {
    private _firebase: FirebaseApp;
    private _auth: FirebaseAuth.Auth;
    private _provider : OAuthProvider;
    private static _instance: Auth;

    static get Instance(): Auth {
        return this._instance;
    }

    public get FirebaseApp(): FirebaseApp {
        return this._firebase;
    }

    constructor() {
        if (Auth._instance) {
            throw new Error("Auth is a singleton class. Use Auth.Instance to get the instance of Auth");
        }

        var host = window.location.hostname + (window.location.port ? ":" + window.location.port : "");

        this._firebase = initializeApp({
            // your application settings
            apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
            authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN || host,
            projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
        });

        this._auth = getAuth(this._firebase);
        this._auth.setPersistence(browserLocalPersistence);

        const provider = new OAuthProvider('microsoft.com');

        provider.setCustomParameters({
            // Force re-consent.
            prompt: 'select_account ',
            // Target specific email with login hint.
            domain_hint: 'fau.edu',
            tenant: process.env.VUE_APP_FIREBASE_MICROSOFT_TENANT
        });

        this._provider = provider;

        Auth._instance = this;
    }
    /**
     * Vue Plugin install function
     * @param app 
     * @param options 
     */
    install(app: App, options? : any): void {
        app.config.globalProperties.$auth = this;
        app.provide('auth', this);
    }
    /**
     * Sign in with popup
     * @returns
     * @throws {AuthError}
     * @throws {FirebaseError}
     */
    async SignInWithPopup() : Promise<UserCredential | null> {        
        localStorage.setItem("loginStatus", "pending");

        var user : UserCredential | null = null

        await signInWithPopup(this._auth, this._provider).then((result) => {
            localStorage.setItem("loginStatus", "LoggedIn");
            user = result;
        }).catch((error) => {
            throw error;
        });

        return user;
    }

    /**
     * Sign in with redirect
     * @returns
     * @throws {AuthError}
     * @throws {FirebaseError}
     */
    
    async SignInWithRedirect() {
        localStorage.setItem("loginStatus", "pending");
        
        await signInWithRedirect(this._auth, this._provider)
    }


    /**
     * Handle redirect
     * @returns Promise<UserCredential | null>
     * @throws {AuthError}
     * @throws {FirebaseError}
     */
    async HandleRedirect() : Promise<UserCredential | null>{
        if(localStorage.getItem("loginStatus") !== "pending") {
            return null;
        }

        var user : UserCredential | null = null;

        await getRedirectResult(this._auth).then((result) => {
            if(!result){
                localStorage.setItem("loginStatus", "Failed");
                throw new AuthError({
                    name: "FailedSignIn",
                    message: "We were unable to sign you in. Please try again."
                });
            }
            localStorage.setItem("loginStatus", "LoggedIn");
            user = result;
        }).catch((error) => {
            throw error;
        });

        return user;
    }

    /**
     * Sign out
     * @returns Promise<void>
     * @throws {FirebaseError}
     */
    async SignOut(): Promise<void> {
        await signOut(this._auth).then(() => {
            localStorage.setItem("loginStatus", "LoggedOut");
        });
    }
    /**
     * Get current user after auth state is ready
     * @returns Promise<User | null>
     * @throws {FirebaseError}
    **/
    async GetCurrentUser(): Promise<User | null> {
        await this._auth.authStateReady();
        const user = this._auth.currentUser;

        return user || null;
    }

    /**
     * Get the token for the current user
     * @returns Promise<string | null>
     * @throws {FirebaseError}
     */
    async GetToken(): Promise<string | null> {
        var user = await this.GetCurrentUser();

        if (!user) {
            return null;
        }

        var token = await user.getIdToken();
        return token;
    }

    /**
     * Check if a user is an FAU user
     * @param user
     * @returns boolean
     */
    IsFAUUser(user: User): boolean {
        var domain = user.email?.split("@")[1];

        if (!domain || domain.length < 7) {
            return false;
        }

        return domain.slice(-7).toLocaleLowerCase().includes("fau.edu");
    }
}
