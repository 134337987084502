export type AuthErrorNames =
    | "INVALID_TOKEN"
    | "NOT_AUTHORIZED"
    | "NOT_AUTHORIZED_FAU"
    | "NOT_FOUND"
    | "BAD_REQUEST"
    | "USER_NOT_FOUND"
    | "LoginNotStarted"
    | "FailedSignIn"
    | "FIREBASE_NOT_INITIALIZED"
    | "AUTH_NOT_INITIALIZED";

export class AuthError extends Error {
    name: AuthErrorNames;
    message: string;
    cause?: any;

    constructor({
        name,
        message,
        cause
    }: {
        name: AuthErrorNames;
        message: string;
        cause?: any;
    }) {
        super();
        this.name = name;
        this.message = message;
        this.cause = cause;
    }
}